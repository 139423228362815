<template>
    <div class="bg-form">
        <div class="container mb-5">
            <div v-if="invoice" class="sub-container">
                <div class="head-form mb-4 d-md-block d-sm-none d-none">
                    <div v-if="bookingCategory" class="top-title">
                        <span>Booking for</span>
                        <h4>{{ bookingCategory }}</h4>
                    </div>
                    <div class="bottom-info row">
                        <div class="col-12 text-center">
                            <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 3.5H10.5C10.6326 3.5 10.7598 3.55268 10.8536 3.64645C10.9473 3.74021 11 3.86739 11 4V10C11 10.1326 10.9473 10.2598 10.8536 10.3536C10.7598 10.4473 10.6326 10.5 10.5 10.5H1.5C1.36739 10.5 1.24021 10.4473 1.14645 10.3536C1.05268 10.2598 1 10.1326 1 10V2C1 1.86739 1.05268 1.74021 1.14645 1.64645C1.24021 1.55268 1.36739 1.5 1.5 1.5H9V3.5ZM2 4.5V9.5H10V4.5H2ZM2 2.5V3.5H8V2.5H2ZM7.5 6.5H9V7.5H7.5V6.5Z" fill="white"/>
                            </svg>
                            <span v-if="invoice.status =='pending'" class="ml-2">Continue Payment</span>
                            <span v-else-if="invoice.status =='completed'" class="ml-2">Paid</span>
                        </div>
                    </div>
                </div>
                <div class="cards">
                    <div v-if="!isConfirmed" class="card text-center">
                        <div class="row">
                            <div class="offset-md-3 col-md-6 col-12">
                                <span v-if="invoice.status =='pending'" class="text-big">Continue with the payment</span>
                                <span v-else-if="invoice.status =='completed'" class="text-big">Your invoice is cleared</span>
                                <small class="d-block">Booking number : <u>{{ invoice.booking_form.booking_number }}</u></small>
                                <p>
                                    Selcare has approved your booking, please check all the details in the invoice and proceed with the payment
                                </p>
                            </div>
                        </div>
                        
                        <!-- <div class="row" v-else>
                            <div class="offset-3 col-6">
                                <span class="text-big">Please wait for a moment</span>
                                <div class="mt-3 mb-3">
                                    <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" /><g><circle cx="16" cy="64" r="16" fill="#7cc6d6"/><circle cx="16" cy="64" r="16" fill="#a8d9e4" transform="rotate(45,64,64)"/><circle cx="16" cy="64" r="16" fill="#c8e7ee" transform="rotate(90,64,64)"/><circle cx="16" cy="64" r="16" fill="#e5f4f7" transform="rotate(135,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(180,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(225,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(270,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(315,64,64)"/><animateTransform attributeName="transform" type="rotate" values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64" calcMode="discrete" dur="1120ms" repeatCount="indefinite"></animateTransform></g></svg>
                                </div>
                                <p>
                                    Our team will check and confirm on your application. Should there be any quiries, we will get back to you.
                                </p>
                                <span class="font-weight-bold">Booking number : 2837</span>
                            </div>
                        </div> -->
                    </div>
                    <div class="card mt-4">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3333 14.6663H2.66667C2.48986 14.6663 2.32029 14.5961 2.19526 14.4711C2.07024 14.3461 2 14.1765 2 13.9997V1.99967C2 1.82286 2.07024 1.65329 2.19526 1.52827C2.32029 1.40325 2.48986 1.33301 2.66667 1.33301H13.3333C13.5101 1.33301 13.6797 1.40325 13.8047 1.52827C13.9298 1.65329 14 1.82286 14 1.99967V13.9997C14 14.1765 13.9298 14.3461 13.8047 14.4711C13.6797 14.5961 13.5101 14.6663 13.3333 14.6663ZM12.6667 13.333V2.66634H3.33333V13.333H12.6667ZM5.33333 5.99967H10.6667V7.33301H5.33333V5.99967ZM5.33333 8.66634H10.6667V9.99967H5.33333V8.66634Z" fill="#394452"/>
                                </svg>
                                Invoice
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="row contents">
                                <div class="col-md-6 col-sm-12 col-12 text-lg-right text-md-right position-relative d-lg-none d-md-none d-sm-block d-block mb-3">
                                    <span class="font-weight-500">{{ invoice.invoice_number }}</span>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <p>
                                        <span class="font-weight-500">{{invoice.address_from.firstname}} {{ invoice.address_from.lastname }}</span> <br />
                                        {{ invoice.address_from.address1 }} <br />
                                        {{ invoice.address_from.address2 }} <br />
                                        {{ invoice.address_from.zipcode }}, {{ invoice.address_from.city }}  <br />
                                        {{ invoice.address_from.province }}
                                    </p>
                                    <div class="d-lg-none d-md-none d-sm-block d-block">
                                        <span class="d-block">{{ invoice.address_from.phone }}</span>
                                        <span class="d-block">{{ invoice.address_from.email }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12 text-lg-right text-md-right position-relative d-md-block d-sm-none d-none">
                                    <span class="font-weight-500">{{ invoice.invoice_number }}</span>
                                    <div class="position-absolute" style="bottom:10px;right:10px;">
                                        <span class="d-block">{{ invoice.address_from.phone }}</span>
                                        <span class="d-block">{{ invoice.address_from.email }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="title-with-bar">
                                        <h6>Registered For</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row contents mt-2">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <p>
                                        <span class="font-weight-500">{{invoice.address_to.firstname}}</span> <br />
                                        {{ invoice.address_to.address1 }} <br />
                                        {{ invoice.address_to.address2 }} <br />
                                        {{ invoice.address_to.zipcode }}, {{ invoice.address_to.city }}  <br />
                                        {{ invoice.address_to.province }} <br />
                                        {{ invoice.address_to.phone }} <br />
                                        {{ invoice.address_to.email }}
                                    </p>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12 text-lg-right text-md-right">
                                    <p>
                                        <span class="font-weight-500">Booking for</span> <br />
                                        {{ bookingDate }} <br />
                                        {{ bookingTimeStart }} - {{ bookingTimeEnd }}
                                    </p>
                                </div>
                            </div>
                            <div v-for="line_item in invoice.line_items" :key="line_item.id">
                                <div v-if="line_item.items.length > 0" class="row mt-4">
                                    <div class="col-12">
                                        <div class="title-with-bar">
                                            <h6>{{ line_item.title }}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="line_item.items.length > 0" class="row contents mt-2">
                                    <div class="col-12">
                                        <div v-for="(item, ind) in line_item.items" :key="item.id" class="row" :class="{ 'mt-1' : ind > 0 }">
                                            <div class="col-md-10 col-sm-8 col-7">
                                                <span>{{ ind+1 }}. {{ item.name }}.</span>
                                            </div>
                                            <div class="col-md-2 col-sm-4 col-5 text-right">
                                                <span v-if="!line_item.is_hide_item_total">RM {{ (item.total).toFixed(2) }} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="line_item.items.length > 0" class="row mt-2">
                                    <div class="col-12">
                                        <div class="div-separator"></div>
                                    </div>
                                </div>
                                <div v-if="line_item.items.length > 0" class="row contents mt-1">
                                    <div class="col-12 text-right">
                                        <span class="mr-3">AMOUNT</span>
                                        <span class="font-weight-bold">RM {{ (line_item.total).toFixed(2) }}</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="title-with-bar">
                                        <div class="row">
                                            <div class="col-6">
                                                <h6>CALCULATION</h6>
                                            </div>
                                            <div class="col-6 text-right">
                                                <h6>CHARGES</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row contents mt-2">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>Subtotal</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.subtotal).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>Processing Fee</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.processing_fee).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>Deposit (Reimbursable)</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.deposit_fee).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>Transportation Fee (Fixed Fee)</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.transportation_fee).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="row mt-1">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>SST (6%)</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.tax).toFixed(2) }}</span>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="title-with-green-bar">
                                        <div class="row">
                                            <div class="offset-md-5 col-md-4 col-sm-6 col-6 text-md-right">
                                                <h5>TOTAL AMOUNT</h5>
                                            </div>
                                            <div class="col-md-3 col-sm-6 col-6 text-right">
                                                <h5 class="font-weight-bold">RM {{ (invoice.total).toFixed(2) }}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 text-right" v-if="invoice.status =='pending'">
                        <!-- <button class="btn btn-print" @click="printInvoice"><i class="fas fa-print"></i> Print</button> -->
                        <!-- <button class="btn btn-submit float-sm-right" @click="submitPayment"><i class="fas fa-credit-card"></i> Pay</button> -->
                        <button v-if="!isBlockPayment" class="btn btn-submit" @click="submitPayment"><i class="fas fa-credit-card"></i> Pay</button>
                        <button v-else class="btn btn-submit btn-blocked" disabled><i class="fas fa-ban"></i> Payment blocked</button>
                    </div>
                    <!-- <div class="mt-4" v-else>
                        <button class="btn btn-print float-sm-right" @click="printInvoice"><i class="fas fa-print"></i> Print</button>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- Checkout Processing Overlay -->
        <div v-if="isScreenOverlay" class="processing-overlay">
            <div v-if="isPaymentProcessing" class="processing">
                <div class="spinner-border spinner-border-sm"></div>
                <span> Processing payment ...</span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import { mapGetters, mapActions } from 'vuex'
import API from '../../../utils/API'
export default {
    data(){
        return {
            invoice: null,
            bookingCategory: null,
            isConfirmed: false,
            isScreenOverlay: false,
            isPaymentProcessing: false,
            isBlockPayment: false
            // bookingDate: null,
            // bookingTimeStart: null,
            // bookingTimeEnd: null
        }
    },
    computed: {
        bookingDate(){
            return moment(this.invoice.booking_start_datetime).format('dddd, D MMMM YYYY')
        },
        bookingTimeStart(){
            return moment(this.invoice.booking_start_datetime).format('hh:mm A')
        },
        bookingTimeEnd(){
            return moment(this.invoice.booking_end_datetime).format('hh:mm A')
        }
    },
    methods: {
        ...mapActions(['createInvoicePayment']),
        goPrevious(){
            this.$emit('go-to', 3)
        },
        submitPayment(){
            //scroll top
            window.scrollTo(0,0)
            this.isScreenOverlay = true
            this.isPaymentProcessing = true

            let payloadData = {
                email: this.invoice.address_to.email,
                phone: this.invoice.address_to.phone,
                fullname: this.invoice.address_to.firstname,
                description: `Invoice payment at ${moment().format('dddd, D MMMM YYYY')}`,
                testmode: true, // for now
                total_price: this.invoice.total,
                total_tax: this.invoice.tax,
                invoice: this.invoice,
                transaction_type: 'invoice'
            }
            this.createInvoicePayment(payloadData)
        },
        async printInvoice(){
            console.log('start print the invoices')
        }
    },
    async mounted(){
        let invoiceRes = await API.get(`/invoices/${this.$route.params.invoiceid}`)
        this.invoice = invoiceRes.data

        //block payment if invoice is not clear more than 48 hours
        let nowTime = moment(new Date())
        let invoiceCreatedTime = moment(this.invoice.createdAt)
        let duration = moment.duration(nowTime.diff(invoiceCreatedTime))
        let durationHours = duration.asHours()
        if(this.invoice.status && this.invoice.status != 'completed' && durationHours >= 48)
            this.isBlockPayment = true
        // console.log('is block payment ? ', this.isBlockPayment)

        //set booking category
        switch (this.invoice.booking_form.category) {
            case 'nurse':
                return this.bookingCategory = 'Nurse'
            case 'physio':
                return this.bookingCategory = 'Physio'
            case 'medical_assistant':
                return this.bookingCategory = 'Medical Assistant'
            case 'care_aide':
                return this.bookingCategory = 'Care Aide'

            default:
                break;
        }

        // console.log('invoice : ', this.invoice)
    }
}
</script>

<style scoped>
.bg-form {
    /* background-color: #E5E5E5; */
}

.top-title {
    text-align: center;
    background-color: #EB5757;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 0px;
}

.bottom-info {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #A91A18;
    margin: -5px 0px 0px 0px;
    padding: 15px 0px;
    border-radius: 8px;
    letter-spacing: 1px;
}
.bottom-info .text-not-yet {
    color: #EB5757;
}

.cards {

}

.card {
    border: unset;
    padding: 15px 40px;
}

.card .card-body .contents {
    padding: 15px 35px 10px 35px;
    font-size: 15px;
}

.div-separator {
    height: 2px;
    background-color: #EBEEF2;
}

.title-with-bar {
    padding: 15px 35px 10px 35px;
    background-color: #EBEEF2;
}
.title-with-green-bar {
    padding: 20px 35px 15px 35px;
    background-color: #7CC6D6;
}

span.text-big {
    font-size: 18px;
    font-weight: 500;
}

.btn.btn-add {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    background-color: #EBEEF2;
    border: 1px solid #DADEE3;
    border-radius: 6px;
    padding: 12px 25px;
}
.btn.btn-cancel {
    color: #ffffff;
    background-color: #858C94;
    padding: 15px 25px;
}
.btn.btn-submit {
    color: #ffffff;
    background-color: #7CC6D6;
    padding: 15px 100px;
}
.btn.btn-submit.btn-blocked {
    background-color: #a91a18;
}
.btn.btn-print {
    color: #ffffff;
    background-color: #eb5757;
    padding: 15px 100px;
}

.processing-overlay {
    position: absolute;
    top: 1px;
    width: 100%;
    height: 100vw;
    background: rgb(255 255 255 / 75%);
    text-align: center;
    padding: 300px 0px;
}

/** Media Queries */
@media (min-width: 992px) {
    .sub-container {
        padding: 0px 150px;
    }
}
@media (max-width: 766px) {
    .card .card-body {
        padding: 0px;
    }
}
@media (max-width: 570px) {
    
}
@media (max-width: 500px) {
    .card {
        padding: 15px 10px;
    }

    .btn.btn-submit {
        width: 100%;
    }
    .btn.btn-print {
        width: 100%;
        margin-bottom: 10px;
    }
}

/** Others */
.font-weight-500 {
    font-weight: 500;
}
</style>